import {tns} from "tiny-slider";

const slider = () => {
    const tinySlider = tns({
        container: '.reviews__slider',
        items: 3,
        slideBy: 'page',
        autoplay: false,
        controls: false,
        // controlsContainer: '.reviews__slider-arrows',
        nav: false,
        // navPosition: 'bottom',
        // navContainer: '.reviews__slider-btns',
        slideBy: 1,
        mouseDrag: true,
        center: true,
        autoHeight: false,
        startIndex: 0,
        responsive: {
          1201:{
              items: 3,
          },
          993: {
              items: 3,
              autoWidth: true,
          },
          769: {
              items: 2
          },
          577: {
              items: 2,
              edgePadding: 0,
              gutter: 15,
          },
          320: {
            items: 1,
            edgePadding: 30,
            gutter: 16,
            autoWidth: true
          },
        },
      });

      // const nav = document.querySelector('.tns-nav'),
      //      navBtns = document.querySelectorAll('.tns-nav button');
      // nav.classList.add('reviews__slider-btns');
      // navBtns.forEach((item) => {
      //   item.classList.add('reviews__slider-btn');
      // });

    //   tinySlider.events.on('transitionEnd', () => {
    //     const tinySliderDots = document.querySelectorAll('.reviews__slider-btn');
    //     tinySliderDots.forEach((item, i) => {
    //         if(i == (tinySlider.getInfo().displayIndex) - 1) {
    //             item.classList.add('reviews__slider-btn_active');
    //         }else {
    //             item.classList.remove('reviews__slider-btn_active');
    //         }
    //     });
    // });
}

export default slider;